<template>
	<div class="container">
		<!-- <Nav :curIndex="5"></Nav> -->
		<div class="account_right">
			<div class="form-box">
				<div class="title">Leave A Message</div>
				<el-form :model="form" :rules="rules" ref="form" label-position="top" :hide-required-asterisk="true" class="form">
					<el-form-item label="Full Name" prop="name">
					    <el-input v-model="form.name" placeholder=""></el-input>
					</el-form-item>
				    <el-form-item label="Post title" prop="title">
				        <el-input v-model="form.title" placeholder=""></el-input>
				    </el-form-item>
				    <el-form-item label="Content" prop="content">
				        <el-input v-model="form.content" placeholder=""></el-input>
				    </el-form-item>
				    <el-form-item label="Code" prop="captcha">
				        <div class="code-box">
				            <el-input v-model="form.captcha" placeholder=""></el-input>
				            <img class="code-img" :src="captchaImg" @click="getCaptcha" />
				        </div>
				    </el-form-item>
				    <el-form-item>
				        <div class="submit-btn" @click="submit">Submit</div>
				    </el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		components: {
		    Nav: () => import('@/views/account/nav.vue')
		},
        data() {
            return {
				form: {
					name: '',
				    title: '',
				    content: '',
				    captcha: '',
                    key: ''
				},
                captchaImg: '',
				rules: {
					name: [
						{ required: true, message: 'Please enter your full name', trigger: 'blur' }
					],
				    title: [
				        { required: true, message: 'Please enter a post title', trigger: 'blur' }
				    ],
				    content: [
				        { required: true, message: 'Please enter content', trigger: 'blur' }
				    ],
				    captcha: [
				        { required: true, message: 'Please enter verification code', trigger: 'blur' }
				    ]
				}
            }
        },
        created() {
            this.getCaptcha()
        },
        methods: {
            getCaptcha() {
                this.$api.captcha().then(res => {
                    this.captchaImg = res.data.img
                    this.form.key = res.data.key
                })
            },
            
            submit() {
				this.$refs.form.validate((valid) => {
				    if(valid) {
                        this.$api.leaveMessage({
                            type: 'comment',
                            captcha: this.form.captcha,
                            key: this.form.key,
                            account: JSON.stringify({
                                name: this.form.name,
                                title: this.form.title,
                                content: this.form.content
                            })
                        },true).then(res => {
                            this.$refs.form.resetFields()
                            this.$message({
                                message: res.message,
                                type: 'success'
                            })
                            this.getCaptcha()
                        }).catch(err => {
                            // console.log(err)
                            this.getCaptcha()
                        })
				    }
				})
			}
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
        	display: flex;
        	width: 100%;
        	box-sizing: border-box;
        	padding: 4rem 0;
        	
        	.account_right {
        		// width: 79%;
				width: 100%;
        		box-sizing: border-box;
        		// padding-left: 3rem;
        		
        		.form-box {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 3rem;
        				font-size: 2rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.form {
        			    width: 100%;
        			    
        			    /deep/ .el-form-item {
        			        margin-bottom: 2.5rem;
        			    }
        			    
        			    .code-box {
        			        display: flex;
        			        align-items: center;
        			        width: 100%;
        					
        					// /deep/ .el-input {
        					// 	width: 30rem;
        					// }
        			        
        			        .code-img {
        			            min-width: 12rem;
        			            max-width: 12rem;
        			            height: 4rem;
        			            margin-left: 2rem;
                                cursor: pointer;
        			        }
        			    }
        			    
        			    .submit-btn {
        					display: inline-block;
        					// min-width: 32rem;
							width: 100%;
        			        height: 5rem;
        			        line-height: 5rem;
        			        box-sizing: border-box;
        			        padding: 0 2.5rem;
        			        border-radius: 4px;
        			        font-size: 1.4rem;
        			        color: #FFFFFF;
        			        text-align: center;
        			        white-space: nowrap;
        			        background-color: #333333;
        			        cursor: pointer;
        			    }
        			}
        		}
        	}
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
        	width: 100%;
        	box-sizing: border-box;
        	padding: 2rem $h5-box-padding;
        	
        	.account_right {
        		width: 100%;
        		
        		.form-box {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 2rem;
        				font-size: 1.8rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.form {
        			    width: 100%;
        			    
        			    /deep/ .el-form-item {
        			        margin-bottom: 2.5rem;
        			    }
        			    
        			    .code-box {
        			        display: flex;
        			        align-items: center;
        			        width: 100%;
        					
        					// /deep/ .el-input {
        					// 	width: 30rem;
        					// }
        			        
        			        .code-img {
        			            min-width: 12rem;
        			            max-width: 12rem;
        			            height: 4rem;
        			            margin-left: 1rem;
                                cursor: pointer;
        			        }
        			    }
        			    
        			    .submit-btn {
        					width: 100%;
        			        height: 4.5rem;
        			        line-height: 4.5rem;
        			        box-sizing: border-box;
        			        padding: 0 2rem;
        			        border-radius: 4px;
        			        font-size: 1.4rem;
        			        color: #FFFFFF;
        			        text-align: center;
        			        white-space: nowrap;
        			        background-color: #333333;
        			        cursor: pointer;
        			    }
        			}
        		}
        	}
        }
    }
</style>